<script>
import { gsap, Circ } from "gsap";
import { onMounted } from "@vue/runtime-core";

export default {
    setup() {
        onMounted(() => {
            gsap.from(".flex_item", {
                opacity: 0,
                y: 20,
                ease: Circ.easeOut,
                stagger: 0.3,
                duration: 0.9,
                delay: 0.5,
            });
        });
    },
};
</script>

<template>
    <section class="landing_page child_wrap">
        <div class="hero">
            <div class="txt_copy flex_col">
                <h1>
                    <span>The Best </span>
                    <span>Blockchain </span>
                    <span>Gateway on </span>
                    🌎
                </h1>
                <p>Cloud-based Ethereum APIs that truly just work.</p>
                <div class="cta btn_mono">
                    <router-link :to="{ name: 'signup' }">
                        <button>Get Started</button>
                    </router-link>
                </div>
            </div>
            <div class="img_copy" id="vector">
                <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655148340/rivet.cloud/eth_3d_ctfa27.png" alt="" />
            </div>
        </div>
        <div class="partner flex_col">
            <h3>Buitl on Rivet</h3>
            <div class="partners">
                <div class="flex_item b">
                    <img src="@/assets/MEW.svg" alt="" />
                </div>
                <div class="flex_item b">
                    <img src="@/assets/Primedao.svg" alt="" />
                </div>
                <div class="flex_item b">
                    <img src="@/assets/Ethercluster.svg" alt="" />
                </div>
                <div class="flex_item b">
                    <img src="@/assets/supra.svg" alt="" />
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.landing_page {
    padding: 5rem 0px;
    @include flex_col(6rem 0);
    @include media("<=phone-tab") {
        padding: 3rem 0;
    }
    .hero {
        @include flex(space-between, center, none);
        @include media("<=tablet") {
            flex-direction: column;
            gap: 2rem;
        }
        position: relative;
        .txt_copy {
            width: 70%;
            gap: 2em 0px;
            @include media(">=tablet", "<=desktop") {
                width: 50%;
            }
            @include media("<=tablet") {
                width: 90%;
            }
            @include media("<=phone-tab") {
                width: 100%;
                text-align: left;
            }
            h1 {
                color: $grey;
                font-size: 3.5em;
                letter-spacing: 1px;
                font-family: "Monument";
                line-height: 86px;
                @include media("<=phone-tab") {
                    font-size: 3em;
                    letter-spacing: 0;
                    line-height: 4.1rem;
                }
                @include media("<=phone") {
                    font-size: 2.8em;
                }
                @include media("<=370px") {
                    font-size: 2.4em;
                }
                span {
                    animation: gradient 6s ease-in-out forwards infinite;
                    background: linear-gradient(0deg, #4484fc 10.18%, #86a8e7 41.75%, #5ffbf1 129.12%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            p {
                color: white;
                font-size: 1.5em;
                @include media("<=phone-tab") {
                    font-size: 1.3em;
                    line-height: 1.8rem;
                }
            }
        }
        .img_copy {
            width: 31%;
            position: absolute;
            right: -30px;
            top: -50px;
            @include media(">=1800px") {
                width: 25%;
            }
            @include media("<=tablet") {
                position: static;
            }
            @include media("<=phone-tab") {
                width: 70%;
            }
            img {
                width: 100%;
                @extend %nodrag;
                animation: wobble 3s ease-in-out infinite forwards;
            }
        }
    }
    .partner {
        gap: 2rem;
        h3 {
            color: $grey;
        }
        .partners {
            width: 60%;
            @include flex(space-between, center, 1rem 0.4rem);
            flex-wrap: wrap;
            @include media("<=1300px") {
                width: 75%;
            }
            @include media("<=desktop") {
                width: 100%;
            }
            .flex_item {
                width: 10rem;
                img {
                    width: 100%;
                }
            }
        }
    }
}
@keyframes wobble {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(50px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes gradient {
    0% {
        background: linear-gradient(0deg, #4484fc 10.18%, #86a8e7 41.75%, #5ffbf1 129.12%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    30% {
        background: linear-gradient(88deg, #4484fc 10.18%, #86a8e7 41.75%, #5ffbf1 129.12%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    60% {
        background: linear-gradient(260deg, #4484fc 10.18%, #86a8e7 41.75%, #5ffbf1 129.12%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    90% {
        background: linear-gradient(310deg, #4484fc 10.18%, #86a8e7 41.75%, #5ffbf1 129.12%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
