<template>
    <section class="child_wrap flex privacy_flex">
        <div class="privacy txt_copy">
            <h2 class="up_case h2-rivet-landing">ironclad privacy</h2>
            <div class="privacy_info">
                <p>At Rivet, we believe that your data (and your customers' data) should never be involuntarily relinquished to anyone.</p>
                <p>
                    That's why we only retain the minimum amount of information necessary to operate our service for the minimum amount of time
                    necessary.
                </p>
                <p>And we will never sell or willingly share your data with anyone, period.</p>
            </div>
            <div class="privacy_btn flex">
                <div class="btn_mono">
                    <router-link :to="{ name: 'signup' }">
                        <button>Sign Up Now</button>
                    </router-link>
                </div>
                <router-link :to="{ name: 'privacy policy' }"> Privacy Policy </router-link>
            </div>
        </div>
        <div class="privacy_img img_copy flex_item">
            <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655150069/rivet.cloud/cloudlock_iieg30.png" alt="" />
        </div>
    </section>
</template>

<script>
import { gsap, Circ } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { onMounted } from "@vue/runtime-core";
export default {
    setup() {
        onMounted(() => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.timeline({
                scrollTrigger: {
                    trigger: ".privacy_flex",
                    start: "top center",
                    end: "bottom bottom",
                    toggleActions: "play play reverse reverse",
                },
            }).from(".privacy_img img", {
                x: 500,
                duration: 0.8,
                ease: Circ.easeOut,
            });
        });
    },
};
</script>

<style lang="scss" scoped>
section {
    justify-content: space-between;
    overflow-x: hidden;
    align-items: center;
    @include media("<=tablet") {
        flex-direction: column;
        gap: 3rem;
    }
    .txt_copy {
        width: 50%;
        @include flex_col(2rem);
        @include media("<=tablet") {
            width: 100%;
        }
        .privacy_info {
            @include flex_col(0.5em);
        }
        .privacy_btn {
            @include flex(flex-start, center, 0rem 1.2rem);
            a {
                color: #a3c1ff;
            }
        }
    }
    .img_copy {
        width: 30%;
        @include media("<=phone") {
            width: 90%;
        }
        @include media(">=phone", "<=phone-tab") {
            width: 70%;
        }
        @include media("<=tablet", ">=phone-tab") {
            display: none;
        }
        img {
            @extend %nodrag;
            width: 100%;
        }
    }
}
</style>
