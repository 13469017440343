<template>
    <section id="Team" class="child_wrap">
        <h2 class="ta_center h2-rivet-landing up_case">Team</h2>
        <div class="team_grid grid">
            <div v-for="indiv in data" class="indiv flex ta_center" :key="indiv.id">
                <div class="img">
                    <a v-if="indiv.url != false" :href="indiv.url">
                        <img :src="indiv.img" alt="" />
                    </a>
                    <img loading="lazy" v-else :src="indiv.img" alt="" />
                </div>
                <div class="team_member">
                    <h2>{{ indiv.name }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { gsap, Circ } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { onMounted } from "@vue/runtime-core";
import db from "@/db/team.json";
export default {
    setup() {
        onMounted(() => {
            gsap.registerPlugin(ScrollTrigger);

            gsap.timeline({
                scrollTrigger: {
                    trigger: "#Team h2",
                    start: "top center",
                    end: "center bottom",
                    toggleActions: "play play reverse reverse",
                },
            }).from(".team_member", {
                ease: Circ.easeOut,
                x: 90,
                opacity: 0,
                duration: 0.8,
                stagger: 0.2,
            });
        });

        return {
            data: db,
        };
    },
};
</script>

<style lang="scss" scoped>
section {
    @include landingSpacing();
    .team_grid {
        padding: 50px 0px;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2rem 3rem;
        width: 100%;
        grid-column-gap: 30px;
        @include media("<=tablet", ">=phone-tab") {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media("<=700px") {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
        }
        .indiv {
            flex-direction: row-reverse;
            align-items: flex-end;
            a {
                text-decoration: none;
                color: white;
            }
            p {
                font-weight: normal;
                padding: 20px 0px;
                color: white;
            }
            .img {
                width: 80%;
                border-radius: 10px;
                z-index: 100;
                overflow: hidden;
                img {
                    width: 100%;
                    @extend %nodrag;
                    z-index: 100;
                    transition: transform 1.6s ease-in-out;
                    box-shadow: 12px 8px 20px rgb(0, 0, 0);
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
            .team_member {
                width: 12%;
                h2 {
                    transform: rotateZ(270deg);
                    padding: 0;
                    color: $skyblue;
                    @include media("<=desktop") {
                        font-size: 1.8em;
                    }
                    @include media("<=phone") {
                        font-size: 1.5em;
                    }
                }
            }
        }
    }
}
</style>
