<script setup>
import { ref } from "@vue/reactivity";
const requests = ref("");
const price = ref("0");
const over1k = ref(false);
let use;
let priceCond;
const prepay = ref(false);
const period = ref("Seconds");
const timeFactor = {
    second: 30 * 24 * 60 * 60,
    block: (30 * 24 * 60 * 60) / 12,
    day: 30,
    month: 1,
};
const showRequest = () => {
    switch (period.value) {
        case "Seconds":
            use = timeFactor.second;
            break;
        case "Block":
            use = timeFactor.block;
            break;
        case "Day":
            use = timeFactor.day;
            break;
        case "Month":
            use = timeFactor.month;
            break;
        default:
            use = timeFactor.second;
            break;
    }
    if (prepay.value) {
        if (parseInt(requests.value) * use < 1000000) {
            priceCond = 1 / 100000;
        } else if (parseInt(requests.value) * use < 100000000) {
            priceCond = 0.995 / 100000;
        } else if (parseInt(requests.value) * use < 1000000000) {
            priceCond = 0.895 / 100000;
        } else {
            priceCond = 0.845 / 100000;
        }
        price.value = Math.ceil(Math.max(0, parseFloat(requests.value * use * priceCond - 30)));
    } else {
        priceCond = 1 / 100000;
        price.value = Math.ceil(Math.max(0, parseInt(requests.value * use * priceCond - 30)));
    }
    if (price.value >= 1000) {
        over1k.value = true;
    } else {
        over1k.value = false;
    }
};
</script>

<template>
    <section class="child_wrap" id="requestimator">
        <div class="request_calc ta_center">
            <h2 class="h2-rivet-landing">RIVET REQUESTIMATOR</h2>
            <p>Enter your anticipated request volume here to find out how much you'll save with Rivet:</p>
            <img src="@/assets/line_dl.svg" alt="" />
            <div class="enter">
                <input @input="showRequest" v-model="requests" type="text" placeholder="Requests" />
                &nbsp;
                <span>per</span>
                &nbsp;
                <select @change="showRequest" v-model="period" class="request" name="period">
                    <option value="Seconds">Seconds</option>
                    <option value="Block">Block</option>
                    <option value="Day">Day</option>
                    <option value="Month">Month</option>
                </select>
                &nbsp;
                <span>≈</span>
                <span class=""> ${{ price }}.00 /month</span>
                <br /><br />
                <input @change="showRequest" v-model="prepay" type="checkbox" name="prepay" id="prepay" />
                &nbsp;
                <span class="discount">Apply DAI/ ETH discount</span>

                <p class="over1k" v-if="over1k">
                    That's a pretty big number. Give us a shout at
                    <a href="mailto:concierge@rivet.cloud">concierge@rivet.cloud</a>.
                </p>

                <div v-if="over1k" class="btn btn_mono">
                    <button>Sign Me Up</button>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
section {
    .request_calc {
        padding: 40px 30px;
        border-radius: 10px;
        background: $offgrey;
        width: 70%;
        margin: 0 auto;
        border: 1px solid white;
        @include media("<=tablet") {
            width: 100%;
        }
        @include media("<=phone-tab") {
            padding: 20px 10px;
        }
        h2 {
            padding: 10px 0px;
            @include media("<=phone-tab") {
                font-size: 1.5em;
            }
        }
        p {
            font-size: 1.1em;
            margin: 15px auto;
            max-width: 80%;
        }
        img {
            padding: 30px 0px;
        }
        .input {
            border: {
                right: none;
                left: none;
                top: none;
                bottom: 1px solid white;
            }
            color: white;
            font-family: inherit;
            font-size: 1em;
            background: none;
            outline: none;
            text-align: center;
            padding: 8px 0px;
        }
        .enter {
            input[type="text"] {
                width: 130px;
                color: #838383;
                @extend .input;
            }
            select {
                @extend .input;
            }
            a {
                text-decoration: none;
                color: white;
            }
            .over1k {
                margin: 20px auto;
            }
        }
    }
}
</style>
