<template>
    <section class="child_wrap rqsn_wrapper flex">
        <div class="carde one flex">
            <div class="right_carde">
                <h2 class="ta_center">3M</h2>
                <div class="btn_color cta">
                    <router-link :to="{ name: 'signup' }">
                        <button>Sign Up with Email</button>
                    </router-link>
                </div>
            </div>
            <div class="left_carde">
                <h3>Free requests per month</h3>
                <p>Signup with valid email address required for free request</p>
                <div class="btn_color cta">
                    <router-link :to="{ name: 'signup' }">
                        <button>Sign Up with Email</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="carde flex flex_dir_rr">
            <div class="right_carde">
                <h2 class="ta_center">$1</h2>
                <div class="btn_color cta">
                    <router-link :to="{ name: 'signup' }">
                        <button>Sign Up with Email</button>
                    </router-link>
                </div>
            </div>
            <div class="left_carde">
                <h3>Per 100, 000 requests</h3>
                <p>Up to 15% off request packages purchased using DAI or ETH (no email address required).</p>
                <div class="btn_color cta">
                    <router-link :to="{ name: 'signup' }">
                        <button>Sign Up with Email</button>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup></script>

<style lang="scss" scoped>
section {
    gap: 0 2rem;
    @include media("<=desktop") {
        flex-direction: column;
        gap: 2rem;
    }
    .carde {
        width: 50%;
        background: white;
        padding: 4rem 0px;
        border-radius: 10px;
        align-items: center;
        justify-content: space-around;
        @include media("<=desktop") {
            width: 100%;
        }
        @include media("<=phone-tab") {
            @include flex_col(1rem);
            padding: 2.5rem 0;
        }
        .right_carde {
            @include flex_col(1.5rem);
            h2 {
                font-weight: bold;
                font-size: 4em;
                color: $blacktext;
                @include media("<=phone-tab") {
                    font-size: 3em;
                }
            }
            .cta {
                @include media("<=phone-tab") {
                    display: none;
                }
                button {
                    padding: 1.5rem 2.4rem;
                }
            }
        }
        .left_carde {
            width: 40%;
            @include flex_col(1.2rem);
            @include media("<=phone-tab") {
                width: 90%;
                text-align: center;
            }
            .cta {
                display: none;
                @include media("<=phone-tab") {
                    display: block;
                }
            }
            h3 {
                font-size: 1.7em;
                color: $blacktext;
                @include media("<=phone-tab") {
                    font-size: 1.5em;
                }
            }
            p {
                color: $offblack;
            }
        }
    }
}
</style>
