<template>
    <div class="child_wrap">
        <section class="two_landing">
            <div class="header">
                <h2 class="h2-rivet-landing">NODE INFRASTRUCTURE MADE EASY</h2>
            </div>
            <div class="why_rivet grid">
                <div class="grid_item flex" v-for="item in why_rivet" :key="item.id">
                    <div class="img">
                        <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655149048/rivet.cloud/rellx_exwz2n.png" alt="" />
                    </div>
                    <div class="copr">
                        <h3>
                            {{ item.Header }}
                        </h3>
                        <p>{{ item.txt }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="sectionTwo">
            <div class="compare_rivet">
                <div class="card_compare">
                    <h3>Before Rivet</h3>

                    <ul>
                        <li>Waking up to server alarms at 3:00 am</li>
                        <li>Waiting for nodes to finish syncing</li>
                        <li>Minimizing queries to control costs</li>
                    </ul>

                    <div class="img prod_chart"></div>
                </div>
                <div class="card_compare">
                    <h3 class="skyblue">With Rivet</h3>

                    <ul>
                        <li>Sleeping in on weekends</li>
                        <li>Making api calls on-demand</li>
                        <li>Optimizing queries to delight customers</li>
                    </ul>

                    <div class="img prod_chart"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import why_rivet from "@/db/why_rivet.json";
import { gsap, Circ, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { onMounted } from "@vue/runtime-core";
onMounted(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".grid_item", {
        opacity: 1,
        y: -20,
        stagger: 0.3,
        duration: 1,
        ease: Circ.easeOut,
        scrollTrigger: {
            trigger: ".two_landing",
            start: "top +=400",
            end: "+=500",
        },
    });

    gsap.from(".card_compare", {
        y: 150,
        opacity: 0,
        stagger: 0.2,
        ease: Power1.easeInOut,
        scrollTrigger: {
            trigger: ".sectionTwo",
            start: "+=100 bottom",
            end: "center bottom",
            toggleActions: "restart play reverse play",
        },
    });
});
</script>

<style lang="scss" scoped>
.child_wrap {
    @include flex_col(4rem);
    @include landingSpacing;
    .two_landing {
        @include flex(space-between, flex-start, none);
        @include media("<=desktop") {
            @include flex_col(2rem);
        }
        .header {
            width: 35%;
            @include media("<=desktop") {
                width: 60%;
            }
            @include media("<=phone-tab") {
                width: 85%;
            }
            @include media("<=phone") {
                width: 100%;
                text-align: center;
            }
            padding: 20px 0px;
            h2 {
                padding: 0px;
            }
            p {
                margin: 20px 0px;
            }
        }
        .why_rivet {
            width: 60%;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.2rem 1rem;
            @include media("<=desktop") {
                width: 100%;
                gap: 1rem 1.8rem;
            }
            @include media("<=phone-tab") {
                grid-template-columns: repeat(1, 1fr);
            }
            .grid_item {
                padding: 20px 0px;
                opacity: 0;
                transform: translateY(0);
                @include flex(space-between, flex-start, none);
                @include media("<=phone") {
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                    text-align: center;
                }
                .img {
                    width: 15%;
                    img {
                        width: 100%;
                        @extend %nodrag;
                    }
                }
                .copr {
                    width: 80%;
                    @include flex_col(1.3rem);
                    @include media("<=phone") {
                        gap: 0.7rem;
                    }
                    h3 {
                        color: white;
                        font-size: 1.3em;
                        text-transform: uppercase;
                    }
                    p {
                        color: $grey;
                    }
                }
            }
        }
    }
    .compare_rivet {
        @include flex(space-between, center, 0 2em);
        @include media("<=phone-tab") {
            flex-direction: column;
            gap: 2rem 0px;
        }
        .card_compare {
            height: 20rem;
            color: white;
            border: 2px solid $skyblue;
            background: $offgrey;
            padding: 20px 50px;
            width: 48%;
            border-radius: 0px;
            @include media("<=tablet") {
                padding: 25px 25px;
                width: 48%;
            }
            @include media("<=phone-tab") {
                width: 100%;
            }
            @include media("<=phone") {
                padding: 20px 30px;
            }
            &:hover {
                border-color: white;
                transition: border-color 0.1s ease-in-out;
            }
            h3 {
                font-size: 1.7em;
                font-weight: bold;
                font-family: "DM sans";
                margin: 30px 0px;
                font-weight: 300;
            }
            ul {
                margin-left: 15px;
                li {
                    margin: 15px 0px;
                }
            }
            .prod_chart {
                margin: 50px auto;
                width: 120px;
            }
        }
    }
}
</style>
