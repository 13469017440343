<template>
    <section class="child_wrap flex egs">
        <div class="privacy img_copy egs_img flex_item">
            <img src="https://res.cloudinary.com/ferventdev/image/upload/v1655150076/rivet.cloud/db_pb0f5f.png" alt="" />
        </div>
        <div class="privacy txt_copy">
            <h2 class="up_case h2-rivet-landing">ENTERPRISE-GRADE SUPPORT</h2>
            <div class="privacy_info">
                <li>Up-to-date, easy-to-navigate documentation.</li>
                <li>Access to real human beings who care about your success.</li>
                <li>99.95% Uptime guaranteed.</li>
            </div>
            <div class="privacy_btn">
                <div class="btn_mono">
                    <router-link :to="{ name: 'signup' }">
                        <button>Sign Up Now</button>
                    </router-link>
                </div>
                <a href="">Docs</a>
                <router-link :to="{ name: 'FAQ' }"> F.A.Q </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { gsap, Circ } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { onMounted } from "@vue/runtime-core";
export default {
    setup() {
        onMounted(() => {
            animateSection();
        });

        const animateSection = () => {
            gsap.registerPlugin(ScrollTrigger);
            gsap.timeline({
                scrollTrigger: {
                    trigger: ".egs",
                    start: "top center",
                    end: "bottom bottom",
                    toggleActions: "play play reverse reverse",
                },
            }).from(".egs_img img", {
                x: -500,
                opacity: 0,
                duration: 0.8,
                ease: Circ.easeOut,
            });
        };
    },
};
</script>

<style lang="scss" scoped>
section {
    justify-content: space-between;
    align-items: center;
    @include media("<=tablet") {
        flex-direction: column-reverse;
        gap: 3rem;
    }
    .txt_copy {
        width: 50%;
        @include flex_col(2rem);
        @include media("<=tablet") {
            width: 100%;
        }
        .privacy_info {
            color: $grey;
            @include flex_col(0.5em);
        }
        .privacy_btn {
            @include flex(flex-start, center, 0rem 1.2rem);
            a {
                color: #a3c1ff;
            }
        }
    }
    .img_copy {
        width: 30%;
        @include media("<=phone") {
            width: 90%;
        }
        @include media(">=phone", "<=phone-tab") {
            width: 70%;
        }
        @include media("<=tablet", ">=phone-tab") {
            display: none;
        }
        img {
            @extend %nodrag;
            width: 100%;
        }
    }
}
</style>
